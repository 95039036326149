
import MineUser from './components/MineUser'
import MineTip from './components/MineTip'
import MineOrder from './components/MineOrder'
import MineHelp from './components/MineHelp'
import MineAd from './components/MineAd'
import MineRec from './components/MineRec'

export default {
    name: 'Mine',
    // layout(context) {
    //     const query = context.query || {};
    //     return query.from == 'head' ? 'default' : 'main-footer'
    // },
    // layout: 'main-footer',
    layout: 'main-footer',
    components: {
        MineUser,
        MineTip,
        MineOrder,
        MineHelp,
        MineAd,
        MineRec
    },
    props: {},
    data() {
        return {}
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('My Account'),
                keywords: this.$translate('mine'),
                description: this.$translate('mine'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
